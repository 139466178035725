import { v4 as uuidv4 } from 'uuid';
import DeviceHost from '../DeviceHost';
import {
  IDeviceDescriptor,
  DeviceAxisConfig,
  DeviceComponent,
  DeviceConnectionType,
} from '../BaseDevice';
import BaseRemote from '../BaseRemote';

import DigitalButtonStateMachine from '../common/DigitalButtonStateMachine';

import { DeviceAxisEvent } from '../events/DeviceAxisEvent';
import { DeviceButtonEvent } from '../events/DeviceButtonEvent';

import { TFunction } from 'i18next';
import RemoteAirturnFluidPedalIcon from './images/remote-airturn-fluidpedal-icon.png';
import RemoteAirturnDigit3Icon from './images/remote-airturn-digit3-icon.png';
import RemoteAirturnDuoIcon from './images/remote-airturn-duo-icon.png';
import RemoteAirturnQuadIcon from './images/remote-airturn-quad-icon.png';
import RemoteAirturnS6Icon from './images/remote-airturn-s6-icon.png';
import AirturnRemoteUI from './UI';

import {
  BluetoothDevice,
  BluetoothCharacteristic,
  IBluetoothProvider,
  BluetoothStatus
} from '@fluidprompter/ipc-interfaces';

const AIRTURN_SERVICE = '7bdb8dc0-6c95-11e3-981f-0800200c9a66';
//                       00001234-0000-1000-8000-00805f9b34fb
const AIRTURN_CHARACTERISTIC_STATE_DIGITAL = '362f71a0-6c96-11e3-981f-0800200c9a66';
const AIRTURN_CHARACTERISTIC_STATE_ANALOG = 'bd066da4-f9ec-4f0d-a53c-1cd99147a641';

const DEVICEINFO_SERVICE = '0000180a-0000-1000-8000-00805f9b34fb';         // 0x180F or 0000180f-0000-1000-8000-00805f9b34fb Battery Service
const DEVICEINFO_MODELNO_CHARACTERISTIC = '00002a24-0000-1000-8000-00805f9b34fb';  // 0x2A19 or 00002a19-0000-1000-8000-00805f9b34fb Battery Characteristic

type DeviceAxisEventOrUndefined = DeviceAxisEvent | undefined;

const AIRTURNREMOTE_TYPE = 'airturn';

class AirturnRemote extends BaseRemote {
  readonly type = AIRTURNREMOTE_TYPE;

  public static readonly DEVICE_TYPE: string = AIRTURNREMOTE_TYPE;

  static PRIMARY_SERVICE_UUID = '7bdb8dc0-6c95-11e3-981f-0800200c9a66';

  static DEVICEINFO_SERVICE_UUID = '0000180a-0000-1000-8000-00805f9b34fb';

  static CHARGING_SERVICE_UUID = '6facfe71-a4c3-4e80-ba5c-533928830727';
  static CHARACTERISTIC_CHARGING_UUID = '90d9a098-9cd8-4a7a-b176-91ffe80909f2';
  static BATTERY_SERVICE = '0000180f-0000-1000-8000-00805f9b34fb';

  _buttons: DigitalButtonStateMachine[];
  previousAxisEvent: DeviceAxisEventOrUndefined[] = [undefined, undefined];

  constructor(deviceHost: DeviceHost) {
    super(deviceHost);
    this.icon = RemoteAirturnDigit3Icon;
    this.name = 'Airturn Remote';
    this.connectionType = DeviceConnectionType.Bluetooth;

    const buttons = [{
      bitmask: 0x01,
      name: 'up',
    }, {
      bitmask: 0x02,
      name: 'left',
    }, {
      bitmask: 0x04,
      name: 'down',
    }, {
      bitmask: 0x08,
      name: 'right',
    }, {
      bitmask: 0x10,
      name: 'ok',
    }, {
      bitmask: 0x20,
      name: 'keyboard',
    }];

    this._buttons = [];
    for (let i = 0; i < buttons.length; i++) {
      const btnInstance = new DigitalButtonStateMachine(buttons[i], this.onButtonEvent.bind(this));
      this._buttons.push(btnInstance);
    }
  }

  getRequestDeviceOptions(): RequestDeviceOptions {
    const requestDeviceOptions: RequestDeviceOptions = {
      filters: [{services: [AIRTURN_SERVICE]}],
      optionalServices: [
        AirturnRemote.DEVICEINFO_SERVICE_UUID,
        AirturnRemote.CHARGING_SERVICE_UUID, AirturnRemote.BATTERY_SERVICE]
    };

    return requestDeviceOptions;
  }

  async onConnect(device: BluetoothDevice)  {
    // Let's check the device model number first.

    await this.deviceHost.BluetoothProvider.discoverServices(device.id);
    // Value is “PED”, “DIGITIII500”, “PEDPro”, “BT200”, “BT200S-2”, “BT200S-4” or “BT200S-6”
    // "QUAD200", "DUO500", "BT500S-6"
    const deviceModelNo = await this.deviceHost.BluetoothProvider.read(device.id, DEVICEINFO_SERVICE, DEVICEINFO_MODELNO_CHARACTERISTIC);
    if (!deviceModelNo) {
      console.log('Model Number characteristic value is null');
      return;
    }

    const deviceModelNoString = new TextDecoder().decode(this.numArrToDataView(deviceModelNo));
    console.log(`Device Model: ${deviceModelNoString}`);

    switch(deviceModelNoString) {
      case 'DIGITIII500':
        this.icon = RemoteAirturnDigit3Icon;
        this.name = 'Airturn Digit-III Remote';
        break;
      case 'DUO500':
        // This is the 2 pedal DUO device.
        this.icon = RemoteAirturnDuoIcon;
        this.name = 'Airturn Duo Pedal';
        break;
      case 'QUAD200':
        // This is the 4 pedal QUAD device.
        this.icon = RemoteAirturnQuadIcon;
        this.name = 'Airturn Quad Pedal';
        break;
      case 'BT500S-6':
        // This is the 6 stomp switch device with 2 analog ports.
        this.icon = RemoteAirturnS6Icon;
        this.name = 'Airturn S-6 Pedal';
        break;
      default:
        // This is an unknown AirTurn device.
        break;
    }
    this.requestDeviceReport();

    await super.onConnect(device);
  }


  async attachServicesAndCharacteristics(
    device: BluetoothDevice,
    provider: IBluetoothProvider,
    subscriptionTopic: string) {

    super.attachServicesAndCharacteristics(device, provider, subscriptionTopic);

    await provider.notify(device.id,
      AIRTURN_SERVICE,
      AIRTURN_CHARACTERISTIC_STATE_DIGITAL,
      (status: BluetoothStatus, char?: BluetoothCharacteristic, error?: number) => {
        if (status === BluetoothStatus.Success && char?.value) {
          this.onNotifyDigital(this.numArrToDataView(char.value));

        } else {
          console.log(`Received error on button notification. Error code is ${error}`);
        }
      },
      uuidv4()
    );

    await provider.notify(device.id,
      AIRTURN_SERVICE,
      AIRTURN_CHARACTERISTIC_STATE_ANALOG,
      (status: BluetoothStatus, char?: BluetoothCharacteristic, error?: number) => {
        if (status === BluetoothStatus.Success && char?.value) {
          this.onNotifyAnalog(this.numArrToDataView(char.value));

        } else {
          console.log(`Received error on button notification. Error code is ${error}`);
        }
      },
      uuidv4()
    );

    //
    // Let's subscribe to battery level updates.
    //
    await this.subscribeToBatteryStatus(device);

    //
    // Let's subscribe to battery charging updates.
    //
    await this.subscribeToChargingService(device);

  }

  //
  // Fired when a device transition from connected to disconnected.
  // This may be due to user interaction to disconnect on purpose or it may be due to an unexpected
  // disconnect (device turned off, moved too far away, etc).
  //
  async onDisconnected() {
    // When the device is disconnected, it is possible it was disconnected while
    // a button was actively being pressed. The button will then be stuck in the
    // pressed state and the repeat press timer may continue to fire indefinitely.
    const buffer = new ArrayBuffer(2);
    const view = new DataView(buffer);
    this.onNotifyDigital(view);

    await super.onDisconnected();
  }

  /**
   * Fired when we receive a BLE characteristic notification for a digital button state change (button being pressed or released).
   * @param {*} notifyData
   */
  onNotifyDigital(notifyData: DataView) {
    // 0x01 = Up
    // 0x02 = Left
    // 0x04 = Down
    // 0x08 = Right
    // 0x10 = Play/Pause
    // 0x20 = Keyboard
    const airturnState = notifyData.getUint8(0);  // Need to get the byte as _unsigned_ value
    for (let i = 0; i < this._buttons.length; i++) {
      this._buttons[i].processState(airturnState);
    }
  }

  /**
   * Fired when we receive a BLE characteristic notification for an analog input state change (value has changed from previous analog input value).
   * @param {*} notifyData
   */
  onNotifyAnalog(notifyData: DataView) {
    //
    // 1. Unpack the analog value which is expressed as a 2-byte value.
    //
    const analogInputNumber = notifyData.getUint8(0) - 1; // Airturn reports a 1-based indexing value, we want a 0-based indexing value.
    // const analogValue = ((notifyData.getUint8(2) & 0xFF) << 8) | (notifyData.getUint8(1) & 0xFF);
    const analogValue = notifyData.getUint16(1, true);
    // console.log(`Analog Value: ${analogValue} (bytes ${notifyData.byteLength}, input # ${analogInputNumber})`);

    // TODO: deviceHost should be able to provide the current state of other control devices such as keyboard keys currently pressed.

    //
    // 2. Build an Axis event to represent the new analog value.
    //
    const axisConfig = new DeviceAxisConfig();
    axisConfig.valueMinimum = 0;
    axisConfig.valueMinimumTolerance = 32;
    axisConfig.valueNeutral = 0;
    axisConfig.valueNeutralTolerance = 32;
    axisConfig.valueMaximum = 32767;  // signed int32 maximum value
    axisConfig.valueMaximumTolerance = 135;

    const axisEvent = new DeviceAxisEvent(this, analogInputNumber, axisConfig, new Event('SyntheticDeviceAxisEvent'), analogValue, this.previousAxisEvent[analogInputNumber]);

    // Save our new AnalogPosition/Value for use in the next event to see what changed.
    this.previousAxisEvent[analogInputNumber] = axisEvent;
    this.emit('axisevent', axisEvent);
  }

  onNotifyBatteryCharging(notifyData: DataView) {
    // Battery charging status has changed
    // 0 =	DISCONNECTED_DISCHARGING	Not connected to power supply, running on battery power. Cell battery AirTurns (PED) only use this state
    // 1 =	CONNECTED_CHARGING	Connected to power supply and charging
    // 2 =	CONNECTED_FULLY_CHARGED	Connected to power supply and fully charged
    // 3 =	CONNECTED_VALIDATION	Connected to power supply and validating battery state. Occurs for 2s when first connected to power supply.
    // 4 =	CONNECTED_FAULT	Connected to power supply and a fault has occurred. User needs to disconnect power supply to exit fault state.
    const chargingState = notifyData.getUint8(0);
    this.batteryCharging = (chargingState > 0 && chargingState < 4);
    this.requestDeviceReport();
  }

  /**
   * Fired when our state machines have detected a user input event.
   * @param {*} buttonName
   * @param {*} eventType
   */
  onButtonEvent(buttonName: string, eventType: string) {
    const eventName = `${buttonName}.${eventType}`;
    console.log(`AirturnRemote.onButtonEvent ${eventName}`);

    // Resolve what action this remote button is mapped to based on remote configuration.
    const proposedAction: string | undefined = undefined;
    // switch(eventName) {
    //   case "ok.down":
    //     proposedAction = 'prompter.state.momentaryplay.start';
    //     break;
    //   case "ok.up":
    //     proposedAction = 'prompter.state.momentaryplay.stop';
    //     break;
    //   default:
    //     break;
    // }

    this.emit('buttonreport', new DeviceButtonEvent(this, buttonName, eventType, proposedAction));
  }

  async subscribeToChargingService(device: BluetoothDevice) {
    try {
      const initialValue = await this.deviceHost.BluetoothProvider.read(
        device.id,
        AirturnRemote.CHARGING_SERVICE_UUID,
        AirturnRemote.CHARACTERISTIC_CHARGING_UUID);

      if (!initialValue) {
        console.log('Battery charging characteristic not found');
        return;
      }

      this.onNotifyBatteryCharging(this.numArrToDataView(initialValue));

      await this.deviceHost.BluetoothProvider.notify(device.id,
        AirturnRemote.CHARGING_SERVICE_UUID,
        AirturnRemote.CHARACTERISTIC_CHARGING_UUID,
        (status: BluetoothStatus, char?: BluetoothCharacteristic, error?: number) => {
          if (status === BluetoothStatus.Success && char?.value) {
            this.onNotifyBatteryCharging(this.numArrToDataView(char.value));
          } else {
            console.log(`Received error on battery notification. Error code is ${error}`);
          }
        },
        ''
      );

    } catch (err) {
      console.log('Error retrieving bluetooth device battery charging status.', err);
    }
  }

  static readonly DeviceKey: string = 'airturn';
  static getDeviceDescriptors(t: TFunction): IDeviceDescriptor[] {
    return [{
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: AirturnRemote.DeviceKey,
      deviceName: 'Airturn FP-1 Fluid Pedal',
      deviceIcon: RemoteAirturnFluidPedalIcon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
      priority: 1,
    }, {
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: AirturnRemote.DeviceKey,
      deviceName: 'Airturn Duo Foot Pedal',
      deviceIcon: RemoteAirturnDuoIcon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
      priority: 1,
    }, {
      connectionType: DeviceConnectionType.Bluetooth,
      deviceKey: AirturnRemote.DeviceKey,
      deviceName: `Airturn Digit-III ${t('connectdevicedialog.remote')}`,
      deviceIcon: RemoteAirturnDigit3Icon,
      requiresPlanLevel: 1,
      requiresBluetooth: true,
      priority: 2,
    }];
  }

  getDeviceUIComponent(): DeviceComponent {
    return AirturnRemoteUI;
  }
}

export default AirturnRemote;